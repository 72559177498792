import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { NzModalService, NzNotificationService } from "ng-zorro-antd";
import { StableDataService } from "../../../service/stableData/stable-data.service";
import { PaperPreviewComponent } from "../../components/paper-preview/paper-preview.component";

@Component({
  selector: "app-peper-list",
  templateUrl: "./peper-list.component.html",
  styleUrls: ["./peper-list.component.scss"]
})
export class PeperListComponent implements OnInit {
  constructor(private fb: FormBuilder, private ajax: AjaxService, private notification: NzNotificationService, private stableData: StableDataService, private dialog: NzModalService) {}

  searchForm: FormGroup;

  tableData = [];
  tableDataCount: number = 0;
  tableCurrentPage: number = 1;
  tablePageSize: number = 12;
  isloading: boolean = true;

  paperTypeSelect = this.stableData.paperType();

  ngOnInit() {
    this.searchForm = this.fb.group({
      name: [],
      type: ["0"],
      // 统一参数
      page: [],
      size: [this.tablePageSize]
    });
    this.searchSubmit();
  }

  searchByButton() {
    this.tableCurrentPage = 1;
    this.searchForm.controls["page"].setValue(1);
    this.searchSubmit();
  }

  searchSubmit() {
    this.isloading = true;
    // 设置页码与分页
    this.searchForm.controls["page"].setValue(this.tableCurrentPage);
    this.searchForm.controls["size"].setValue(this.tablePageSize);
    this.ajax.CommonAjax("get", "/DocumentTools/download_list", this.searchForm.value).subscribe(res => {
      this.tableDataCount = res.count;
      this.tableData = res.list;
      this.isloading = false;
    });
  }

  paperPreview(paper_id, paper_title) {
    this.dialog.create({
      nzTitle: paper_title,
      nzContent: PaperPreviewComponent,
      nzComponentParams: {
        paper_id: paper_id
      },
      nzWrapClassName: "previewWrap",
      nzWidth: 1048,
      nzFooter: null
    });
  }

  deletePaper(paper_id) {
    this.ajax.CommonAjax("post", "/DocumentTools/download_delete", { id: paper_id }).subscribe(res => {
      if (res.status === 200) {
        this.notification.create("success", "文书删除成功", "");
        this.searchSubmit();
      }
    });
  }
}
