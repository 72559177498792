import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[click-stop]"
})
export class ClickStopDirective {
  constructor() {}
  @HostListener("click", ["$event"])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
