import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AjaxService } from "../service/ajax/ajax.service";

@Component({
  selector: "app-print-page",
  templateUrl: "./print-page.component.html",
  styleUrls: ["./print-page.component.scss"]
})
export class PrintPageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private ajax: AjaxService) {}

  paperHtml;

  ngOnInit() {
    const routeQuery = this.route.snapshot.queryParams;
    if (routeQuery.hasOwnProperty("paperId")) {
      this.getPaperData(routeQuery.paperId);
    }
  }

  getPaperData(paperId) {
    // 获取文书信息
    this.ajax
      .CommonAjax("post", "/DocumentTools/download_insert", {
        content_id: paperId
      })
      .subscribe(res => {
        if (res.status === 200) {
          this.paperHtml = res.list.template_content;
          setTimeout(() => {
            window.print();
          }, 1000);
        }
      });
  }
}
