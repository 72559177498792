import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StableDataService } from "../../../service/stableData/stable-data.service";
import { FnService } from "../../../service/fn/fn.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { NzModalService } from "ng-zorro-antd";

@Component({
  selector: "app-temp-edit",
  templateUrl: "./temp-edit.component.html",
  styleUrls: ["./temp-edit.component.scss"]
})
export class TempEditComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private stableData: StableDataService,
    private fn: FnService,
    private route: ActivatedRoute,
    private ajax: AjaxService,
    private dialog: NzModalService,
    private router: Router
  ) { }

  editForm: FormGroup;
  // editConfig = this.stableData.editConfig();
  inputKeyTips; // 文本属性选择提示
  inputTitle; // 自定义属性名称
  inputType; // 文本属性的类型
  inputId; // 文本属性id
  tempEdit = "";
  previewTempId; // canvas预览模板id
  ueEditor;
  tempId;
  paperTypeSelect = this.stableData.paperTypeEdit();
  codeKeySelect = [];
  routeQuery;
  isloading: boolean = false;
  fatherId; // 套用的父模板的id
  tempRenderArray;
  config = {
    height: 500,
    base_url: 'https://finymce.laipaifafu.com',
    language: 'zh_CN',
    content_css_cors: false,
    tinymceScriptSrc: 'https://finymce.laipaifafu.com/tinymce.min.js',
    plugins: [
      'advlist autolink lists link image media charmap print preview anchor',
      'searchreplace visualblocks fullscreen',
      'insertdatetime table advtable code help wordcount',
      'importword',
      'pasteword',
      'fullscreen'
    ],
    toolbar: 'undo redo | formatselect | bold italic forecolor | alignleft aligncenter alignright alignjustify fontselect fontsizeselect | bullist numlist outdent indent | importword | fullscreen | viewEdit |',
    setup: (editor) => {
      editor.ui.registry.addButton('viewEdit', {
        icon: 'preview',
        text: '预览',
        onAction: () => {
          this.fn.print(editor.getContent());
        }
      })
    },
    menu: {
      file: {
        title: '文件',
        items: 'newdocument'
      }
    }
  }
  editor;


  ngOnInit() {
    this.editForm = this.fb.group({
      id: [],
      choose: ["", Validators.required],
      type: ["1", Validators.required],
      template_title: ["", Validators.required],
      img_path: ["test", Validators.required]
    });
    this.routeQuery = this.route.snapshot.queryParams;
    this.editForm.controls["choose"].setValue(this.routeQuery.hasOwnProperty("choose") ? this.routeQuery.choose : "0");
    this.tempId = this.routeQuery.hasOwnProperty("tempId") ? this.routeQuery.tempId : "";
    this.fatherId = this.routeQuery.hasOwnProperty("fatherId") ? this.routeQuery.fatherId : "";
    this.getCodeData();
  }

  async getEditor(evt) {
    this.editor = evt.editor;
    const _queryParams = this.route.snapshot.queryParams;
    this.tempId = this.routeQuery.hasOwnProperty("tempId") ? this.routeQuery.tempId : "";
    this.fatherId = this.routeQuery.hasOwnProperty("fatherId") ? this.routeQuery.fatherId : "";
    if (_queryParams.tempId || _queryParams.fatherId) {
      let content: any = await this.getTempData();
      this.tempEdit = content;
      this.editor.setContent(content);
    }
  }

  // 获取到文本属性select列表
  getCodeData() {
    this.ajax.CommonAjax("post", "/DocumentTools/case_named", {}).subscribe(res => {
      this.codeKeySelect = res.list ? res.list : [];
    });
  }

  // 获取模板详情
  getTempData() {
    return new Promise(resolve => {
      this.ajax.CommonAjax("post", "/DocumentTools/template", { templateasd_id: this.tempId ? this.tempId : this.fatherId }).subscribe(res => {
        if (res.status === 200) {
          // 如果是套用的模板，需要修改choose和id，用于创建个人模板
          if (this.fatherId) {
            res.list.choose = "0";
            res.list.id = "";
          }
          this.fn.AjaxToForm(res.list, this.editForm).subscribe(() => {
            resolve(res.list.template_content);
          });
        }
      });
    });
  }

  // 表单提交
  async formSubmit(redirect: boolean = false) {
    this.tempRenderArray = await this.fn.A4Pagination();
    if (this.editForm.valid) {
      this.isloading = true;
      const _formData = Object.assign(this.editForm.value);
      _formData.template_content = this.tempEdit;
      this.ajax.CommonAjax("post", "/DocumentTools/template_add", _formData).subscribe(async res => {
        if (res.status === 200) {
          await this.coverSubmit(res.list.template_id);
          this.isloading = false;
          if (redirect) {
            this.router.navigate(["/paperEdit"], { queryParams: { tempId: res.list.template_id } });
          } else {
            this.dialog.success({
              nzTitle: "操作成功",
              nzOnOk: () => {
                history.go(-1);
              }
            });
          }
        }
      });
    }
  }

  async coverSubmit(temp_id) {
    return new Promise((resolve, reject) => {
      this.previewTempId = temp_id;
      const _formData = Object.assign(this.editForm.value);
      _formData.template_content = this.tempEdit;
      _formData.id = temp_id;
      setTimeout(async () => {
        _formData.img_path = await this.fn.tempCoverCanvas(temp_id);
        this.ajax.CommonAjax("post", "/DocumentTools/template_add", _formData).subscribe(res => {
          resolve('');
        });
      }, 500);
    });
  }

  // 文本属性选项变动时
  inputKeyChange(e) {
    this.codeKeySelect.map(selectItem => {
      if (selectItem.hasOwnProperty("children")) {
        selectItem.children.map(item => {
          if (item.id === e) {
            this.inputKeyTips = item.combination;
            this.inputTitle = item.introduce;
            this.inputType = item.type;
            this.inputId = item.id;
          }
        });
      }
    });
  }

  // 编辑器初始化
  async editorReady(e) {
    this.ueEditor = e.instance;
    if (this.tempId || this.fatherId) {
      this.ueEditor.focus();
      this.ueEditor.setContent(await this.getTempData());
    }
  }

  // 插入代码
  injectCode() {
    const injectCodeValue = `#lpy@${this.inputId}${this.inputTitle ? "." + this.inputTitle : ""}${this.inputType ? "." + this.inputType : "text"}@#`;
    this.editor.insertContent(injectCodeValue);
  }

  goBack() {
    history.go(-1);
  }
}
