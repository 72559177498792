import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PlatformComponent } from "./platform/platform.component";
import { PeperListComponent } from "./platform/pages/peper-list/peper-list.component";
import { TempPersonalListComponent } from "./platform/pages/temp-personal-list/temp-personal-list.component";
import { TempPublicListComponent } from "./platform/pages/temp-public-list/temp-public-list.component";
import { TempEditComponent } from "./platform/pages/temp-edit/temp-edit.component";
import { PaperEditComponent } from "./platform/pages/paper-edit/paper-edit.component";
import { PaperModifyComponent } from "./platform/pages/paper-modify/paper-modify.component";
import { PrintPageComponent } from "./print-page/print-page.component";
import { KeywordListComponent } from "./platform/pages/keyword-list/keyword-list.component";
import { KeywordEditComponent } from "./platform/pages/keyword-edit/keyword-edit.component";

const routes: Routes = [
  {
    path: "",
    component: PlatformComponent,
    children: [
      {
        path: "paperList",
        component: PeperListComponent,
        data: {
          breadcrumb: "文书列表"
        }
      },
      {
        path: "paperEdit",
        component: PaperEditComponent,
        data: {
          breadcrumb: "文书创建"
        }
      },
      {
        path: "paperModify",
        component: PaperModifyComponent,
        data: {
          breadcrumb: "文书内容修改"
        }
      },
      {
        path: "tempPersonal",
        component: TempPersonalListComponent,
        data: {
          breadcrumb: "我的模板"
        }
      },
      {
        path: "tempPublic",
        component: TempPublicListComponent,
        data: {
          breadcrumb: "公共模板"
        }
      },
      {
        path: "tempEdit",
        component: TempEditComponent,
        data: {
          breadcrumb: "模板内容编辑"
        }
      },
      {
        path: "keywordList",
        component: KeywordListComponent,
        data: {
          breadcrumb: "关键字列表"
        }
      },
      {
        path: "keywordEdit",
        component: KeywordEditComponent,
        data: {
          breadcrumb: "关键字编辑"
        }
      },
      {
        path: "",
        component: PeperListComponent,
        data: {
          breadcrumb: "文书列表"
        }
      }
    ]
  },
  { path: "printPage", component: PrintPageComponent },
  {
    path: "*",
    component: PeperListComponent,
    data: {
      breadcrumb: "文书列表"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
