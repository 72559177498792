import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class StableDataService {
  constructor() {}
  // 编辑器配置
  editConfig() {
    return {
      autoFloatEnabled: false,
      initialFrameHeight: 750,
      initialFrameWidth: "100%",
      autoHeightEnabled: false,
      elementPathEnabled: false,
      wordCount: false,
      maximumWords: 20000,
      zIndex: 99,
      retainOnlyLabelPasted: true,
      pasteplain: true,
      filterTxtRules: (function() {
        function transP(node) {
          node.tagName = "p";
          node.setStyle();
        }
        return {
          //直接删除及其字节点内容
          "-": "script style object iframe embed input select",
          p: {
            $: {}
          },
          br: {
            $: {}
          },
          div: {
            $: {}
          },
          li: {
            $: {}
          },
          caption: transP,
          th: transP,
          tr: transP,
          h1: transP,
          h2: transP,
          h3: transP,
          h4: transP,
          h5: transP,
          h6: transP
        };
      })(),
      toolbars: [
        [
          "undo", //撤销
          "redo", //重做
          "bold", //加粗
          "indent", //首行缩进
          "italic", //斜体
          "underline", //下划线
          "strikethrough", //删除线
          "subscript", //下标
          "superscript", //上标
          "source", //源代码
          "horizontal", //分隔线
          "removeformat", //清除格式
          "insertrow", //前插入行
          "insertcol", //前插入列
          "mergeright", //右合并单元格
          "mergedown", //下合并单元格
          "deleterow", //删除行
          "deletecol", //删除列
          "splittorows", //拆分成行
          "splittocols", //拆分成列
          "splittocells", //完全拆分单元格
          "deletecaption", //删除表格标题
          "inserttitle", //插入标题
          "mergecells", //合并多个单元格
          "deletetable", //删除表格
          "insertparagraphbeforetable", //"表格前插入行"
          "fontfamily", //字体
          "fontsize", //字号
          "paragraph", //段落格式
          "justifyleft", //居左对齐
          "justifyright", //居右对齐
          "justifycenter", //居中对齐
          "justifyjustify", //两端对齐
          "forecolor", //字体颜色
          "insertorderedlist", //有序列表
          "insertunorderedlist", //无序列表
          "rowspacingtop", //段前距
          "rowspacingbottom", //段后距
          "lineheight", //行间距
          "touppercase", //字母大写
          "tolowercase", //字母小写
          "inserttable" //插入表格
        ]
      ]
    };
  }
  paperType() {
    return [{ label: "全部", value: "0" }, { label: "执行", value: "1" }, { label: "拍卖", value: "2" }, { label: "成交", value: "3" }];
  }

  paperTypeEdit() {
    return [{ label: "执行", value: "1" }, { label: "拍卖", value: "2" }, { label: "成交", value: "3" }];
  }

  keywordType() {
    return [
      { label: "文字类", value: "text" },
      { label: "数字类", value: "digital" },
      { label: "汉字日期", value: "date_text" },
      { label: "日期", value: "date_time" },
      { label: "日期（带小时）", value: "time" }
    ];
  }

  // 最近几年
  recentYears() {
    const currentYear = new Date().getFullYear() - 5;
    let yearsArray = [];
    for (let i = 0; i < 15; i++) {
      yearsArray.push({
        label: currentYear + i,
        value: String(currentYear + i)
      });
    }
    return yearsArray;
  }
}
