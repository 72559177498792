import { Component, Input, OnInit } from "@angular/core";
import { FnService } from "../../../../service/fn/fn.service";
import { AjaxService } from "../../../../service/ajax/ajax.service";
import * as XLSX from "xlsx";
import { NzModalService } from "ng-zorro-antd";
import { XlsxDataSelectComponent } from "../../../components/xlsx-data-select/xlsx-data-select.component";
type AOA = any[][];
import * as download from "../../../../../assets/js/download.min";
import { cloneDeep } from "lodash";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "paper-edit-detail",
  templateUrl: "./paper-edit-detail.component.html",
  styleUrls: ["./paper-edit-detail.component.scss"]
})
export class PaperEditDetailComponent implements OnInit {
  constructor(private fn: FnService, private ajax: AjaxService, private dialog: NzModalService, private route: ActivatedRoute) {}

  _tempHtml;
  temp;
  tempRender = "";
  tempRenderArray;
  inputArray: any[] = []; // 左侧操作input项数组
  caseData: AOA; // 导入的案件信息xlsx数据
  personData: AOA; // 导入的案件信息xlsx数据
  isReanalysis: boolean = false;

  @Input()
  get tempHtml() {
    return this._tempHtml;
  }
  set tempHtml(val) {
    if (val) {
      this._tempHtml = val;
      this.tempInit(val);
    }
  }

  ngOnInit() {}

  focusSelect(e) {
    e.target.select();
  }

  tempInit(tempHtml) {
    // 生成左侧输入模块数组
    this.inputArray = [];
    this.temp = tempHtml;
    const _tempClone = tempHtml;
    let codeExtract = _tempClone.match(/#lpy@(.*?)@#/g);
    if (this.route.snapshot.routeConfig.path === "paperEdit" || (this.route.snapshot.routeConfig.path === "paperModify" && this.isReanalysis)) {
      codeExtract = codeExtract.filter((codeItem, index) => {
        return codeExtract.indexOf(codeItem) === index;
      });
      const codeExtractLength = codeExtract.length;
      for (let i = 0; i < codeExtractLength; i++) {
        const _keyTitleObj = JSON.parse(codeExtract[i].replace(/#lpy@(.*?)\.(.*?)\.(.*?)@#/g, `{"origin":"#lpy@$1.$2.$3@#","id":"$1","title":"$2","type":"$3"}`));
        this.fn.tempFormatTransfer(_keyTitleObj).then(res => {
          this.inputArray.push(res);
          if (i >= codeExtractLength - 1) {
            this.tempRend();
          }
        });
      }
    } else if (this.route.snapshot.routeConfig.path === "paperModify" && !this.isReanalysis) {
      this.isReanalysis = true;
      this.tempInit(_tempClone.replace(/<span lpan data='{"origin":"(.*?)",.*?}'>.*?<\/span>/g, "$1"));
    }
  }

  tempRend() {
    let _tempRender = cloneDeep(this.temp);
    const _inputArrayClone = cloneDeep(this.inputArray);
    const _inputArrayCloneLength = _inputArrayClone.length;
    // 当前是创建文书页面
    for (let i = 0; i < _inputArrayCloneLength; i++) {
      let arrayItem = _inputArrayClone[i];
      // 对代码进行html替换，转换成span标签
      const reg = new RegExp(arrayItem["origin"], "g");
      _tempRender = _tempRender.replace(
        reg,
        `<span lpan data='{"origin":"${arrayItem["origin"]}","id":"${arrayItem["id"]}","value":"${arrayItem["value"]}","readableValue":"${arrayItem["readableValue"]}","title":"${
          arrayItem["title"]
        }","type":"${arrayItem["type"]}"}'>${arrayItem["readableValue"]}</span>`
      );
      if (i >= _inputArrayCloneLength - 1) {
        // 渲染html
        this.tempRender = _tempRender;
        setTimeout(async () => {
          this.tempRenderArray = await this.fn.A4Pagination();
        }, 500);
      }
    }
  }

  // 编辑区域输入
  inputChange(e) {
    this.fn.tempFormatTransfer(e).then(res => {
      e = res;
      this.tempRend();
    });
  }

  customUpload = item => {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const wb: XLSX.WorkBook = XLSX.read(e.target.result, { type: "binary" });
      let ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
      this.caseData = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      ws = wb.Sheets[wb.SheetNames[1]];
      this.personData = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.xlsxDataSelect();
    };
    reader.readAsBinaryString(item);
    return false;
  };

  xlsxDataSelect() {
    this.dialog.create({
      nzTitle: "快速导入",
      nzWidth: 1100,
      nzContent: XlsxDataSelectComponent,
      nzComponentParams: { caseData: this.caseData, personData: this.personData },
      nzOnOk: ref => {
        this.insertXlsxData(ref.caseIndex + 1);
      }
    });
  }

  insertXlsxData(caseIndex) {
    this.ajax.CommonAjax("post", "/DocumentTools/case_named", {}).subscribe(res => {
      let caseNameArray = [];
      res.list.map((casenameItem, index) => {
        if (casenameItem.hasOwnProperty("children")) {
          caseNameArray = caseNameArray.concat(casenameItem.children);
        }
        if (index >= res.list.length - 1) {
          this.inputArray.map(inputArrayItem => {
            caseNameArray.map(caseCodeItem => {
              if (caseCodeItem.id === inputArrayItem["id"]) {
                this.caseData[0].map((xlsxDataItem, xlsxDataItemIndex) => {
                  if (xlsxDataItem === caseCodeItem.introduce) {
                    let currentValue = this.caseData[caseIndex][xlsxDataItemIndex];
                    if (inputArrayItem["type"] === "time") {
                      currentValue = new RegExp("\\d*?-\\d*?-\\d*? \\d*?:\\d*?:\\d*", "g").test(currentValue)
                        ? currentValue.replace(/(\d*?)-(\d*?)-(\d*?) (\d*?):\d*?:\d*/, "$1年$2月$3日 $4时")
                        : new Date();
                    }
                    inputArrayItem["value"] = currentValue ? currentValue : inputArrayItem["title"];
                  }
                });
              }
            });
          });
          this.tempRend();
        }
      });
    });
  }

  downloadTemp() {
    let x = new XMLHttpRequest();
    x.open("GET", "http://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/%E6%A1%88%E4%BB%B6%E6%A8%A1%E6%9D%BF.xls", true);
    x.responseType = "blob";
    x.onload = e => {
      download(e.target["response"], "案件模板.xls", "");
    };
    x.send();
  }
}
