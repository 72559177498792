import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { StableDataService } from "../../../../service/stableData/stable-data.service";
import { AjaxService } from "../../../../service/ajax/ajax.service";
import { TempPreviewComponent } from "../../../components/temp-preview/temp-preview.component";
import { NzModalService } from "ng-zorro-antd";

@Component({
  selector: "paper-edit-temp-select",
  templateUrl: "./paper-edit-temp-select.component.html",
  styleUrls: ["./paper-edit-temp-select.component.scss"]
})
export class PaperEditTempSelectComponent implements OnInit {
  constructor(private fb: FormBuilder, private ajax: AjaxService, private stableData: StableDataService, private dialog: NzModalService) {}

  searchForm: FormGroup;

  tableData = [];
  tableDataCount: number = 0;
  tableCurrentPage: number = 1;
  tablePageSize: number = 12;

  tabIndex: number = 0;
  selectedTempTabIndex: number = 0;
  selectedTempId: number = -1;
  paperTypeSelect = this.stableData.paperType();

  @Output() tempSelectChange = new EventEmitter();

  ngOnInit() {
    this.searchForm = this.fb.group({
      name: [],
      type: ["0"],
      choose: ["0"],
      // 统一参数
      page: [],
      size: [this.tablePageSize]
    });
    this.searchSubmit();
  }

  pageChange(pageIndex) {
    this.tableCurrentPage = pageIndex;
    this.searchForm.controls["page"].setValue(pageIndex);
    this.searchSubmit();
  }

  searchSubmit() {
    // 设置页码与分页
    this.searchForm.controls["page"].setValue(this.tableCurrentPage);
    this.searchForm.controls["size"].setValue(this.tablePageSize);
    this.ajax.CommonAjax("get", "/DocumentTools/template_list", this.searchForm.value).subscribe(res => {
      this.tableDataCount = res.count;
      this.tableData = res.list;
    });
  }

  tempSelect(_tempid) {
    this.selectedTempTabIndex = this.tabIndex;
    this.selectedTempId = _tempid;
    this.tempSelectChange.emit(_tempid);
  }

  tabChange(e) {
    this.tabIndex = e.index;
    this.searchForm.controls["choose"].setValue(String(this.tabIndex));
    this.pageChange(1);
  }

  tempPreview(temp_data) {
    this.dialog.create({
      nzTitle: temp_data.template_title,
      nzContent: TempPreviewComponent,
      nzComponentParams: {
        temp_data: temp_data
      },
      nzWidth: 1048,
      nzFooter: null
    });
  }
}
