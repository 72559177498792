import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { FnService } from "../service/fn/fn.service";
import { NzModalService } from "ng-zorro-antd";

@Component({
  selector: "app-platform",
  templateUrl: "./platform.component.html",
  styleUrls: ["./platform.component.scss"]
})
export class PlatformComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private fn: FnService, private dialog: NzModalService) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data["breadcrumb"]) {
              return child.snapshot.data["breadcrumb"];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((customData: any) => {
        this.breadcrumb = customData;
      });
  }

  breadcrumb;

  async ngOnInit() {
    if (this.route.snapshot.queryParams.hasOwnProperty("client") && this.route.snapshot.queryParams.hasOwnProperty("user_id")) {
      const _params = this.route.snapshot.queryParams;
      const codeConfirm = await this.fn.docEncode(_params);
      if (codeConfirm === _params.token) {
        localStorage.userinfo = JSON.stringify({
          user_id: _params.user_id,
          user_name: _params.user_name,
          client: _params.client
        });
        window.location.href = window.location.origin;
      } else {
        localStorage.clear();
        this.dialog.error({
          nzTitle: "用户信息校验失败",
          nzContent: "请从壬影平台重新打开文书工具",
          nzOnOk: () => {
            window.close();
          }
        });
      }
    }
  }
}
