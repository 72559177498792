import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import ResizeObserver from "resize-observer-polyfill";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { ActivatedRoute } from "@angular/router";
import { asBlob } from "../../../../assets/js/html-docx";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { FnService } from "../../../service/fn/fn.service";

@Component({
  selector: "app-paper-edit",
  templateUrl: "./paper-edit.component.html",
  styleUrls: ["./paper-edit.component.scss"]
})
export class PaperEditComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private dialog: NzModalService, private route: ActivatedRoute, private msg: NzMessageService, private ajax: AjaxService, private fn: FnService) {}

  stepIndex: number = 0;
  resizeObserver;
  isloading: boolean = false;
  stepWidth = 1900;
  translateX: string = "translateX(4000px)";
  stepAnimated: boolean = false;
  tempID; // 选择的模板ID
  tempHtml; // 模板html内容
  fileUrl; // 文件上传之后返回的url
  paperId; // 文书提交之后返回的id

  @ViewChild("StepContainer") StepContainer: ElementRef;
  @ViewChild("BasicComponent") BasicComponent;
  @ViewChild("DetailComponent") DetailComponent;

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      this.stepAnimated = false;
      this.stepWidth = this.StepContainer.nativeElement.clientWidth;
      this.translateX = `translateX(-${this.stepWidth * this.stepIndex}px)`;
    });
    this.resizeObserver.observe(this.StepContainer.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  stepForward() {
    this.stepAnimated = true;
    this.stepIndex -= this.stepIndex > 0 ? 1 : 0;
    this.translateX = `translateX(-${this.stepWidth * this.stepIndex}px)`;
  }

  stepNextCheck() {
    // 当前为基本信息时，需要检查是否填写完整
    this[`step${this.stepIndex + 1}`]();
  }

  stepNext() {
    this.stepAnimated = true;
    this.stepIndex += this.stepIndex < 3 ? 1 : 0;
    this.translateX = `translateX(-${this.stepWidth * this.stepIndex}px)`;
  }

  stepJump(stepIndex) {
    this.stepAnimated = true;
    this.stepIndex += stepIndex;
    this.translateX = `translateX(-${this.stepWidth * this.stepIndex}px)`;
  }

  // 获取模板信息
  getTempHtml(e) {
    this.tempID = e;
    this.ajax.CommonAjax("post", "/DocumentTools/template", { templateasd_id: this.tempID }).subscribe(async res => {
      this.tempHtml = res.list.template_content;
    });
  }

  step1() {
    // 先判断表单是否填写完整
    let _form = this.BasicComponent.editForm;
    for (const i in _form.controls) {
      _form.controls[i].markAsDirty();
      _form.controls[i].updateValueAndValidity();
    }
    if (_form.valid) {
      // 然后判断是否URL中有tempid参数
      const _queryParams = this.route.snapshot.queryParams;
      if (_queryParams.hasOwnProperty("tempId")) {
        this.tempID = _queryParams.tempId;
        this.getTempHtml(this.tempID);
        this.stepJump(2);
      } else {
        this.stepNext();
      }
    } else {
      this.dialog.warning({
        nzTitle: "操作提示",
        nzContent: "标题与案件编号为必填项"
      });
    }
  }

  step2() {
    if (this.tempID) {
      this.stepNext();
    } else {
      this.dialog.warning({
        nzTitle: "操作提示",
        nzContent: "请选择一个模板后再点击下一步"
      });
    }
  }

  // 对内容进行转换
  step3() {
    this.isloading = true;
    this.paperSubmit(this.DetailComponent.tempRender);
  }

  async paperSubmit(tempLive) {
    const fileBlob = asBlob(`<!DOCTYPE html> <html> <head> </head> <body>${tempLive}</body> </html>`);
    const postObj = { template_id: this.tempID };
    Object.assign(postObj, this.BasicComponent.editForm.value);
    postObj["doc_content"] = tempLive;
    postObj["doc_address"] = this.fileUrl = await this.fn.paperUpload(fileBlob, this.BasicComponent.editForm.value);
    this.ajax.CommonAjax("post", "/DocumentTools/download_add", postObj).subscribe(res => {
      this.paperId = res.list.download_id;
      this.stepNext();
      this.isloading = false;
    });
  }

  goBack() {
    history.go(-1);
  }
}
