import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
const serverAddress = "https://screen.laipaiya.com/index.php/Apinterface";

@Injectable({
  providedIn: "root"
})
export class AjaxService {
  constructor(private http: HttpClient) {}

  //ajax set Params
  HttpSetParams(data) {
    if (data) {
      let params = new HttpParams();
      Object.keys(data).forEach(key => {
        if (data[key] || data[key] === 0) {
          params = params.append(key, data[key]);
        }
      });
      return params;
    }
  }

  HttpSetBody(data) {
    if (data) {
      let params = new FormData();
      Object.keys(data).forEach(key => {
        params.append(key, data[key]);
      });
      return params;
    }
  }

  // default ajax
  CommonAjax(method, url, val): Observable<any> {
    if (method == "post") {
      return this.http.post(serverAddress + url, this.HttpSetBody(val));
    } else if (method == "get") {
      return this.http.get(serverAddress + url, {
        params: this.HttpSetParams(val)
      });
    }
  }
}
