import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd";
import { Md5 } from "ts-md5";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";

@Injectable()
export class HttpAuth implements HttpInterceptor {
  constructor(private router: Router, private dialog: NzModalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //设置默认请求头
    const publicKey = "93b6858f12b48bb492b7a49f9c60331e";
    const publicKeyLength = publicKey.length;
    const timeStamp = (new Date().getTime() / 1000).toFixed(0);
    let user_id, user_name, client;
    if (localStorage.userinfo) {
      const localUserinfo = JSON.parse(localStorage.userinfo);
      user_id = localUserinfo.user_id;
      user_name = localUserinfo.user_name;
      client = localUserinfo.client;
    }
    let publicKeyArray = [];
    for (let i = 0; i < publicKeyLength / 3; i++) {
      publicKeyArray.push(publicKey.substring(3 * i, 3 * i + 3));
    }
    let publicKeyEncode = "";
    publicKeyArray.map((item, index) => {
      publicKeyEncode += item + timeStamp.charAt(index);
    });
    const tokenEncoded = Md5.hashStr(Base64.stringify(Utf8.parse(`${publicKeyEncode}${Md5.hashStr(user_name)}${Md5.hashStr(user_id)}${Md5.hashStr(client)}${Md5.hashStr(timeStamp)}`)));
    let authReq;
    authReq = req.clone({
      setHeaders: {
        Client: client,
        Token: String(tokenEncoded),
        Id: user_id,
        Time: timeStamp
      }
    });
    //handle()将请求转发到下一个拦截器，最终传给后端服务器
    return next.handle(authReq).pipe(
      tap(
        (e: HttpEvent<any>) => {
          if (e instanceof HttpResponse) {
            if (e["body"].status != 200 && e["body"].status) {
              if (e["body"].status === 401) {
                // 身份验证错误
                this.dialog.error({
                  nzTitle: "身份校验失败",
                  nzContent: "请重新打开文书工具"
                });
                // localStorage.clear();
                // this.router.navigate(["/login"]);
              } else {
                this.dialog.error({
                  nzTitle: "发生错误",
                  nzContent: e["body"].message
                });
              }
            }
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.dialog.error({
              nzTitle: "网络错误",
              nzContent: `错误码：${error.status}`
            });
          }
        }
      ),
      catchError(() => {
        return next.handle(authReq);
      })
    );
  }
}
