import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { FnService } from "../../../service/fn/fn.service";
import { NzModalService } from "ng-zorro-antd";
import { asBlob } from "../../../../assets/js/html-docx";
import * as download from "../../../../assets/js/download.min";

@Component({
  selector: "app-paper-modify",
  templateUrl: "./paper-modify.component.html",
  styleUrls: ["./paper-modify.component.scss"]
})
export class PaperModifyComponent implements OnInit {
  constructor(private route: ActivatedRoute, private ajax: AjaxService, private fn: FnService, private dialog: NzModalService) {}

  routeQuery;
  paperData;
  isloading: boolean = false;

  @ViewChild("paperEditDetailComponent") paperEditDetailComponent;

  ngOnInit() {
    this.routeQuery = this.route.snapshot.queryParams;
    if (this.routeQuery.hasOwnProperty("paperId")) {
      this.getPaperData();
    }
  }

  getPaperData() {
    this.ajax
      .CommonAjax("post", "/DocumentTools/download_insert", {
        content_id: this.routeQuery.paperId
      })
      .subscribe(res => {
        if (res.status === 200) {
          this.paperData = res.list;
          const temp = res.list.template_content;
          let newInputArray = [];
          this.paperData.template_content = temp.replace(/<span lpan data='{"origin":"(.*?)".*?<\/span>/g, "$1");
          temp.replace(/{.*?"origin":(.*?)}/g, matchItem => {
            const _jsonFormat = JSON.parse(matchItem);
            if (
              newInputArray.length <= 0 ||
              newInputArray.filter(item => {
                return item.origin === _jsonFormat.origin;
              }).length <= 0
            ) {
              newInputArray.push(_jsonFormat);
            }
          });
          setTimeout(() => {
            this.paperEditDetailComponent.inputArray = newInputArray;
            this.paperEditDetailComponent.tempRend();
          }, 500);
        }
      });
  }

  // 文书提交
  async paperSubmit(isDownload: boolean = false) {
    this.isloading = true;
    const tempLive = this.paperEditDetailComponent.tempRender;
    const fileBlob = asBlob(`<!DOCTYPE html> <html> <head> </head> <body>${tempLive}</body> </html>`);
    const fileUrl: any = await this.fn.paperUpload(fileBlob, this.paperData);
    this.ajax
      .CommonAjax("post", "/DocumentTools/download_add", {
        id: this.routeQuery.paperId,
        doc_address: fileUrl,
        doc_content: tempLive
      })
      .subscribe(res => {
        this.isloading = false;
        if (res.status === 200) {
          if (isDownload) {
            let x = new XMLHttpRequest();
            x.open("GET", fileUrl, true);
            x.responseType = "blob";
            x.onload = e => {
              download(e.target["response"], decodeURIComponent(fileUrl).replace(/.*?docStorage\//g, ""));
            };
            x.send();
          }
          this.dialog.success({
            nzTitle: "文书修改成功",
            nzOnOk: () => {
              history.go(-1);
            }
          });
        }
      });
  }

  goBack() {
    history.go(-1);
  }
}
