import { Inject, Injectable, RendererFactory2 } from "@angular/core";
import { Observable } from "rxjs";
import { format, isDate } from "date-fns";
import { StableDataService } from "../stableData/stable-data.service";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { DOCUMENT } from "@angular/common";
import * as html2canvas from "html2canvas";
import { Md5 } from "ts-md5";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import nzhcn from "nzh/cn";

@Injectable({
  providedIn: "root"
})
export class FnService {
  constructor(
    private stableData: StableDataService,
    private msg: NzMessageService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document,
    private dialog: NzModalService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ossClient;
  ossConfig = {
    region: "oss-cn-hangzhou",
    accessKeyId: "LTAI1SUgbwNlexjP",
    accessKeySecret: "o8QbxKtB8OAoVtUATN5wfZtqGQkXXr",
    bucket: "laipai-img"
  };
  fileFolder = "docStorage/"; // oss上传文件夹
  renderer;

  coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== "false";
  }

  // ajax JSON数据导入Form
  AjaxToForm(_obj, form): Observable<any> {
    return new Observable(subscriber => {
      if (_obj) {
        let _keys = Object.keys(_obj);
        let _length = _keys.length;
        _keys.forEach((key, index) => {
          if (form.contains(key)) {
            form.controls[key].setValue(_obj[key]);
          }
          if (index >= _length - 1) {
            subscriber.next();
            subscriber.complete();
            subscriber.unsubscribe();
          }
        });
      }
    });
  }

  // 文书上传
  async paperUpload(file, basicInfo) {
    this.ossClient = await new OSS(this.ossConfig);
    return new Promise((resolve, reject) => {
      this.ossClient
        .put(
          `${this.fileFolder}${basicInfo.doc_title}_${basicInfo.case_year}${basicInfo.court_code}${basicInfo.case_type}${basicInfo.case_code}${basicInfo.other_info ? basicInfo.other_info : ""
          }_${format(new Date(), "YYYY-MM-DD HH:mm:ss")}.doc`,
          file
        )
        .then(uploadResponse => {
          if (uploadResponse.res.status === 200) {
            resolve(uploadResponse.url);
          } else {
            this.msg.error("文件上传失败，请重新上传");
          }
        });
    });
  }

  // 模板封面制作
  tempCoverCanvas(tempId) {
    return new Promise(resolve => {
      try {
        // toBlob兼容性
        if (!HTMLCanvasElement.prototype.toBlob) {
          Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
            value: function (callback, type, quality) {
              let canvas = this;
              setTimeout(function () {
                let binStr = atob(canvas.toDataURL(type, quality).split(",")[1]);
                let len = binStr.length,
                  arr = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                  arr[i] = binStr.charCodeAt(i);
                }
                callback(new Blob([arr], { type: type || "image/png" }));
              });
            }
          });
        }
        //@ts-ignore
        html2canvas(document.getElementById("tempRenderForCanvas"), { scale: 1 }).then(canvas => {
          this.renderer.appendChild(document.getElementById("tempCoverCanvas"), canvas);
          canvas.toBlob(async _blob => {
            resolve(await this.tempCoverUpload(_blob, tempId));
          });
        });
      } catch (e) {
        this.dialog.success({
          nzTitle: "模板封面制作失败",
          nzContent: `错误代码：${e}`
        });
      }
    });
  }

  // 模板封面上传
  async tempCoverUpload(file, tempId) {
    this.ossClient = await new OSS(this.ossConfig);
    return new Promise((resolve, reject) => {
      this.ossClient.put(`${this.fileFolder}tempCover_${tempId}`, file).then(uploadResponse => {
        if (uploadResponse.res.status === 200) {
          resolve(uploadResponse.url);
        } else {
          this.msg.error("文件上传失败，请重新上传");
        }
      });
    });
  }

  // A4分页
  A4Pagination() {
    const eleChild = document.getElementsByClassName("A4PaginationEle")[0].children;
    return new Promise((resolve, reject) => {
      const tempRenderArray = [""];
      let heightCount = 0,
        pushIndex = 0;
      const heightMax = 1414 - 242;
      if (eleChild && eleChild.length > 0) {
        const _length = eleChild.length;
        for (let i = 0; i < _length; i++) {
          const eleStyle = window.getComputedStyle(eleChild[i]);
          const currentElemntHeight = Number(eleStyle.height.replace("px", "")) + Number(eleStyle.marginBottom.replace("px", "")) + Number(eleStyle.marginTop.replace("px", ""));
          if (heightCount + Number(currentElemntHeight) >= heightMax) {
            // 如果高度超出了，则往数组中插入下一条数据
            pushIndex += 1;
            heightCount = 0;
            tempRenderArray[pushIndex] = "";
            tempRenderArray[pushIndex] += eleChild[i].outerHTML;
          } else {
            heightCount += currentElemntHeight;
            tempRenderArray[pushIndex] += eleChild[i].outerHTML;
          }
          if (i >= _length - 1) {
            resolve(tempRenderArray);
          }

        }
      } else {
        this.msg.error("Error：找不到A4Pagination，无法进行分页操作");
      }
    });
  }

  // 文书工具加密
  docEncode(cutom_data = null) {
    return new Promise((resolve, reject) => {
      const publicKey = "93b6858f12b48bb492b7a49f9c60331e";
      const publicKeyLength = publicKey.length;
      const timeStamp = cutom_data ? cutom_data.time : (new Date().getTime() / 1000).toFixed(0);
      let user_id, user_name, client, localUserinfo;
      if (localStorage.userinfo) {
        localUserinfo = JSON.parse(localStorage.userinfo);
      }
      user_id = cutom_data ? cutom_data.user_id : localUserinfo.user_id;
      user_name = cutom_data ? cutom_data.user_name : localUserinfo.user_name;
      client = cutom_data ? cutom_data.client : localUserinfo.client;
      const publicKeyArray = [];
      for (let i = 0; i < publicKeyLength / 3; i++) {
        publicKeyArray.push(publicKey.substring(3 * i, 3 * i + 3));
        if (i >= publicKeyLength / 3 - 1) {
          let publicKeyEncode = "";
          publicKeyArray.map((item, index) => {
            publicKeyEncode += item + timeStamp.charAt(index);
          });
          resolve(Md5.hashStr(Base64.stringify(Utf8.parse(`${publicKeyEncode}${Md5.hashStr(user_name)}${Md5.hashStr(user_id)}${Md5.hashStr(client)}${Md5.hashStr(timeStamp)}`))));
        }
      }
    });
  }

  // 数字转汉字型日期
  digitalDateToCn(digitalDate) {
    const replaceArray = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
    let cnString = "";
    digitalDate = String(digitalDate);
    if (digitalDate.length === 2) {
      if (digitalDate.charAt(0) === "1") {
        cnString = "十" + replaceArray[digitalDate.charAt(1)];
        if (digitalDate.charAt(1) === "0") {
          cnString = "十";
        }
      } else if (digitalDate.charAt(1) === "0") {
        cnString = replaceArray[digitalDate.charAt(0)] + "十";
      } else {
        cnString = replaceArray[digitalDate.charAt(0)] + "十" + replaceArray[digitalDate.charAt(1)];
      }
    } else {
      digitalDate.split("").map(item => {
        cnString += replaceArray[Number(item)];
      });
    }
    return cnString;
  }

  // 模板格式转换
  tempFormatTransfer(inputItem) {
    return new Promise((resolve, reject) => {
      switch (inputItem.type) {
        case "text":
          inputItem["readableValue"] = inputItem["value"] = inputItem.value ? inputItem.value : inputItem.title;
          break;
        case "digital":
          inputItem["readableValue"] = inputItem["value"] = inputItem.value ? inputItem.value : 0;
          break;
        case "time":
          // 时间类型
          if (inputItem.hasOwnProperty("value") && !isDate(inputItem.value)) {
            // 如果当前的value不是date类型，则当前情况为文书编辑-读取文书，如果是date类型，则当前情况为date类型的输入
          } else {
            const _time = inputItem.value ? inputItem.value : new Date();
            inputItem["value"] = `${_time.getFullYear()}/${_time.getMonth() + 1}/${_time.getDate()} ${_time.getHours()}:${_time.getMinutes()}:${_time.getSeconds()}`;
            inputItem["readableValue"] = `${_time.getFullYear()}年${_time.getMonth() + 1}月${_time.getDate()}日 ${_time.getHours()}时`;
          }
          break;
        case "date_text":
          // 大写日期类型
          if (inputItem.hasOwnProperty("value") && !isDate(inputItem.value)) {
            // 如果当前的value不是date类型，则当前情况为文书编辑-读取文书，如果是date类型，则当前情况为date类型的输入
          } else {
            const _time = inputItem.value ? inputItem.value : new Date();
            inputItem["value"] = `${_time.getFullYear()}/${_time.getMonth() + 1}/${_time.getDate()} ${_time.getHours()}:${_time.getMinutes()}:${_time.getSeconds()}`;
            inputItem["readableValue"] = `${this.digitalDateToCn(_time.getFullYear())}年${this.digitalDateToCn(_time.getMonth() + 1)}月${this.digitalDateToCn(_time.getDate())}日`;
          }
          break;
        case "date_time":
          // 日期类型
          if (inputItem.hasOwnProperty("value") && !isDate(inputItem.value)) {
            // 如果当前的value不是date类型，则当前情况为文书编辑-读取文书，如果是date类型，则当前情况为date类型的输入
          } else {
            const _time = inputItem.value ? inputItem.value : new Date();
            inputItem["value"] = `${_time.getFullYear()}/${_time.getMonth() + 1}/${_time.getDate()} ${_time.getHours()}:${_time.getMinutes()}:${_time.getSeconds()}`;
            inputItem["readableValue"] = `${_time.getFullYear()}年${_time.getMonth() + 1}月${_time.getDate()}日`;
          }
          break;
      }
      resolve(inputItem);
    });
  }

  print(text) {
    try {
      const context = this;
      if (navigator.userAgent.indexOf('Chrome') < 0) return this.dialog.warning({ nzTitle: '为了更好的体验请使用Chrome浏览器！' });
      const iframe = document.createElement('iframe');
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframe.contentDocument.body.innerHTML = text;
      setTimeout(() => {
        iframe.contentWindow.print();
        iframe.remove();
        if (context.hasOwnProperty('loading')) {
          context['loading'] = false;
        }
      }, 800)
    } catch (e) {
      console.error("模板生成打印异常");
    }

  }
}
