import { Directive, HostListener, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Directive({
  selector: "[formValidCheck]"
})
export class FormValidCheckDirective {
  constructor() {}

  @Input() form: FormGroup;

  @HostListener("click", ["$event"])
  clickEvent() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
  }
}
