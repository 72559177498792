import { Component, Input, OnInit } from "@angular/core";
import * as download from "../../../../../assets/js/download.min";
import { NzModalService } from "ng-zorro-antd";
import { SendEmailComponent } from "../../../components/send-email/send-email.component";
import { Router } from "@angular/router";

@Component({
  selector: "paper-edit-success",
  templateUrl: "./paper-edit-success.component.html",
  styleUrls: ["./paper-edit-success.component.scss"]
})
export class PaperEditSuccessComponent implements OnInit {
  constructor(private dialog: NzModalService, private router: Router) {}

  _downloadUrl;
  _paperId;

  @Input()
  get downloadUrl() {
    return this._downloadUrl;
  }
  set downloadUrl(val) {
    this._downloadUrl = val;
  }

  @Input()
  get paperId() {
    return this._paperId;
  }
  set paperId(val) {
    this._paperId = val;
  }

  ngOnInit() {}

  goBack() {
    this.router.navigate(["/paperList"]);
  }

  // 再写一篇重定向
  writeAgain() {
    window.location.reload();
  }

  sendByEmail() {
    this.dialog.create({
      nzTitle: "发送文书到邮箱",
      nzFooter: null,
      nzContent: SendEmailComponent,
      nzComponentParams: {
        paperId: this.paperId
      }
    });
  }

  downloadPaper() {
    let x = new XMLHttpRequest();
    x.open("GET", this.downloadUrl, true);
    x.responseType = "blob";
    x.onload = e => {
      download(e.target["response"], decodeURIComponent(this.downloadUrl).replace(/.*?docStorage\//g, ""));
    };
    x.send();
  }
}
