import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { StableDataService } from "../../../service/stableData/stable-data.service";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { NzModalService, NzNotificationService } from "ng-zorro-antd";
import { TempPreviewComponent } from "../../components/temp-preview/temp-preview.component";

@Component({
  selector: "app-temp-personal-list",
  templateUrl: "./temp-personal-list.component.html",
  styleUrls: ["./temp-personal-list.component.scss"]
})
export class TempPersonalListComponent implements OnInit {
  constructor(private fb: FormBuilder, private stableData: StableDataService, private ajax: AjaxService, private dialog: NzModalService, private notification: NzNotificationService) {}

  searchForm: FormGroup;

  tableData = [];
  tableDataCount: number = 0;
  tableCurrentPage: number = 1;
  tablePageSize: number = 18;
  isloading: boolean = true;
  zipLoading: boolean = false;

  paperTypeSelect = this.stableData.paperType();

  ngOnInit() {
    this.searchForm = this.fb.group({
      name: [],
      type: ["0"],
      choose: ["0"],
      // 统一参数
      page: [],
      size: [this.tablePageSize]
    });
    this.searchSubmit();
  }

  downloadZip() {
    this.zipLoading = true;
    this.ajax.CommonAjax("post", "/DocumentTools/template_zip", { choose: 0 }).subscribe(res => {
      this.zipLoading = false;
      if (res.status == 200) {
        window.open(`https://screen.laipaiya.com/${res.data.substring(1)}`);
      }
    });
  }

  pageChange(pageIndex) {
    this.tableCurrentPage = pageIndex;
    this.searchForm.controls["page"].setValue(pageIndex);
    this.searchSubmit();
  }

  searchSubmit() {
    this.isloading = true;
    // 设置页码与分页
    this.searchForm.controls["page"].setValue(this.tableCurrentPage);
    this.searchForm.controls["size"].setValue(this.tablePageSize);
    this.ajax.CommonAjax("get", "/DocumentTools/template_list", this.searchForm.value).subscribe(res => {
      this.tableDataCount = res.count;
      this.tableData = res.list;
      this.isloading = false;
    });
  }

  tempPreview(temp_data) {
    this.dialog.create({
      nzTitle: temp_data.template_title,
      nzContent: TempPreviewComponent,
      nzComponentParams: {
        temp_data: temp_data
      },
      nzWrapClassName: "previewWrap",
      nzWidth: 1048,
      nzFooter: null
    });
  }

  deleteTemp(tempId) {
    this.ajax.CommonAjax("post", "/DocumentTools/template_delete", { id: tempId }).subscribe(res => {
      if (res.status === 200) {
        this.notification.create("success", "模板删除成功！", "");
        this.searchSubmit();
      }
    });
  }
}
