import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AjaxService } from "../../../service/ajax/ajax.service";
import * as download from "../../../../assets/js/download.min";
import { SendEmailComponent } from "../send-email/send-email.component";
import { NzModalService } from "ng-zorro-antd";
import { FnService } from "../../../service/fn/fn.service";

@Component({
  selector: "app-paper-preview",
  templateUrl: "./paper-preview.component.html",
  styleUrls: ["./paper-preview.component.scss"]
})
export class PaperPreviewComponent implements OnInit {
  constructor(private ajax: AjaxService, private dialog: NzModalService, private fn: FnService) {}

  _paper_id;
  paperHtml;
  downloadUrl;
  tempRenderArray;

  @Input()
  get paper_id() {
    return this._paper_id;
  }
  set paper_id(val) {
    this._paper_id = val;
    this.getPaperData();
  }

  ngOnInit() {}

  getPaperData() {
    // 获取文书信息
    this.ajax
      .CommonAjax("post", "/DocumentTools/download_insert", {
        content_id: this.paper_id
      })
      .subscribe(res => {
        if (res.status === 200) {
          this.paperHtml = res.list.template_content;
          setTimeout(async () => {
            this.tempRenderArray = await this.fn.A4Pagination();
          }, 500);
        }
      });
    // 获取文书下载地址
    this.ajax
      .CommonAjax("post", "/DocumentTools/download_address", {
        content_id: this.paper_id
      })
      .subscribe(res => {
        if (res.status === 200) {
          this.downloadUrl = res.list.doc_address;
        }
      });
  }

  downloadPaper() {
    let x = new XMLHttpRequest();
    x.open("GET", this.downloadUrl, true);
    x.responseType = "blob";
    x.onload = e => {
      download(e.target["response"], decodeURIComponent(this.downloadUrl).replace(/.*?docStorage\//g, ""));
    };
    x.send();
  }

  sendByEmail() {
    this.dialog.create({
      nzTitle: "发送文书到邮箱",
      nzFooter: null,
      nzContent: SendEmailComponent,
      nzComponentParams: {
        paperId: this.paper_id
      }
    });
  }
}
