import { Component, Input, OnInit } from "@angular/core";
import { StableDataService } from "../../../service/stableData/stable-data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { NzNotificationService } from "ng-zorro-antd";
import { FnService } from "../../../service/fn/fn.service";

@Component({
  selector: "app-keyword-edit",
  templateUrl: "./keyword-edit.component.html",
  styleUrls: ["./keyword-edit.component.scss"]
})
export class KeywordEditComponent implements OnInit {
  constructor(private fn: FnService, private stableData: StableDataService, private fb: FormBuilder, private ajax: AjaxService, private notification: NzNotificationService) {}

  @Input()
  get keyword_data() {
    return this._keyword_data;
  }
  set keyword_data(val) {
    this._keyword_data = val;
  }

  editForm: FormGroup;
  keywordTypeSelect = this.stableData.keywordType();
  isloading: boolean = false;
  _keyword_data;

  ngOnInit() {
    this.editForm = this.fb.group({
      id: [],
      type: ["text", Validators.required],
      combination: ["", Validators.required],
      introduce: ["", Validators.required]
    });
    if (this.keyword_data) {
      this.fn.AjaxToForm(this.keyword_data, this.editForm).subscribe();
    }
  }

  formSubmit() {
    this.isloading = true;
    this.ajax.CommonAjax("post", "/DocumentTools/named_add", this.editForm.value).subscribe(res => {
      this.isloading = false;
      if (res.status === 200) {
        this.notification.create("success", "操作成功", "");
        this.editForm.reset();
        this.editForm.controls["type"].setValue("text");
      }
    });
  }
}
