import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AjaxService } from "../../../../service/ajax/ajax.service";
import { StableDataService } from "../../../../service/stableData/stable-data.service";
import { PaperPreviewComponent } from "../../../components/paper-preview/paper-preview.component";
import { NzModalService } from "ng-zorro-antd";

@Component({
  selector: "paper-edit-basic-info",
  templateUrl: "./paper-edit-basic-info.component.html",
  styleUrls: ["./paper-edit-basic-info.component.scss"]
})
export class PaperEditBasicInfoComponent implements OnInit {
  constructor(private fb: FormBuilder, private ajax: AjaxService, private stableData: StableDataService, private dialog: NzModalService) {}

  editForm: FormGroup;

  RelatedPaper = [];

  caseCodeSelect = [];
  yearsSelect = this.stableData.recentYears();

  ngOnInit() {
    this.editForm = this.fb.group({
      doc_title: ["", Validators.required],
      case_year: [String(new Date().getFullYear()), Validators.required],
      case_type: [""],
      case_code: ["", Validators.required],
      court_code: [""],
      other_info: [""]
    });
    this.getCourtData();
    this.editForm.valueChanges.subscribe(() => {
      if (this.editForm.valid) {
        this.getRelatedPaper();
      }
    });
  }

  getCourtData() {
    this.ajax.CommonAjax("post", "/DocumentTools/download_case", {}).subscribe(res => {
      if (res.status === 200) {
        const _res = res["list"];
        this.editForm.controls["court_code"].setValue(_res.hasOwnProperty("court_code") && _res.court_code ? _res.court_code[0].code_name : "");
        this.caseCodeSelect = res.list.case_type;
      }
    });
  }

  getRelatedPaper() {
    this.ajax.CommonAjax("post", "/DocumentTools/download_related", this.editForm.value).subscribe(res => {
      if (res.status === 200) {
        this.RelatedPaper = res.list;
      }
    });
  }

  paperPreview(paper_id, paper_title) {
    this.dialog.create({
      nzTitle: paper_title,
      nzContent: PaperPreviewComponent,
      nzComponentParams: {
        paper_id: paper_id
      },
      nzWidth: 750,
      nzFooter: null
    });
  }
}
