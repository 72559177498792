import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-xlsx-data-select",
  templateUrl: "./xlsx-data-select.component.html",
  styleUrls: ["./xlsx-data-select.component.scss"]
})
export class XlsxDataSelectComponent implements OnInit {
  constructor() {}

  _caseData;
  caseHeader;
  caseContent;

  _personData;
  personHeader;
  personContent;

  caseIndex = 0;

  @Input()
  get caseData() {
    return this._caseData;
  }
  set caseData(val) {
    this._caseData = val;
    this.caseHeader = val[0];
    this.caseContent = val.slice(1);
  }

  @Input()
  get personData() {
    return this._personData;
  }
  set personData(val) {
    this._personData = val;
    this.personHeader = val[0];
    this.personContent = val.slice(1);
  }

  ngOnInit() {}
}
