import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { NzModalRef, NzModalService } from "ng-zorro-antd";

@Component({
  selector: "app-send-email",
  templateUrl: "./send-email.component.html",
  styleUrls: ["./send-email.component.scss"]
})
export class SendEmailComponent implements OnInit {
  constructor(private fb: FormBuilder, private ajax: AjaxService, private dialogRef: NzModalRef, private dialog: NzModalService) {}

  editForm: FormGroup;
  isloading: boolean = false;
  _paperId;

  @Input()
  get paperId() {
    return this._paperId;
  }
  set paperId(val) {
    this._paperId = val;
  }

  ngOnInit() {
    this.editForm = this.fb.group({
      mail: ["", Validators.required],
      title: ["", Validators.required],
      content_id: ["", Validators.required]
    });
    this.editForm.controls["content_id"].setValue(this.paperId);
  }

  formSubmit() {
    if (this.editForm.valid) {
      this.isloading = true;
      this.ajax.CommonAjax("post", "/DocumentTools/Send_email", this.editForm.value).subscribe(res => {
        this.isloading = false;
        if (res.status === 200) {
          this.dialog.success({
            nzTitle: "邮件发送成功",
            nzOnOk: () => {
              this.dialogRef.close();
            }
          });
        }
      });
    }
  }
}
