import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { NzModalService, NzNotificationService } from "ng-zorro-antd";
import { StableDataService } from "../../../service/stableData/stable-data.service";
import { KeywordEditComponent } from "../keyword-edit/keyword-edit.component";

@Component({
  selector: "app-keyword-list",
  templateUrl: "./keyword-list.component.html",
  styleUrls: ["./keyword-list.component.scss"]
})
export class KeywordListComponent implements OnInit {
  constructor(private fb: FormBuilder, private ajax: AjaxService, private notification: NzNotificationService, private dialog: NzModalService, private stableData: StableDataService) {}

  searchForm: FormGroup;

  tableData = [];
  tableDataCount: number = 0;
  tableCurrentPage: number = 1;
  tablePageSize: number = 12;
  isloading: boolean = true;

  keywordTypeSelect = this.stableData.keywordType();

  ngOnInit() {
    this.searchForm = this.fb.group({
      type: [],
      // 统一参数
      page: [],
      size: [this.tablePageSize]
    });
    this.searchSubmit();
  }

  searchByButton() {
    this.tableCurrentPage = 1;
    this.searchForm.controls["page"].setValue(1);
    this.searchSubmit();
  }

  searchSubmit() {
    this.isloading = true;
    // 设置页码与分页
    this.searchForm.controls["page"].setValue(this.tableCurrentPage);
    this.searchForm.controls["size"].setValue(this.tablePageSize);
    this.ajax.CommonAjax("get", "/DocumentTools/named_list", this.searchForm.value).subscribe(res => {
      this.tableDataCount = res.count;
      this.tableData = res.list;
      this.isloading = false;
    });
  }

  keywordDialog(keyword_data = "") {
    this.dialog.create({
      nzTitle: keyword_data ? "修改关键字" : "新建关键字",
      nzContent: KeywordEditComponent,
      nzComponentParams: { keyword_data: keyword_data },
      nzFooter: null,
      nzWidth: 800,
      nzOnCancel: () => {
        this.searchSubmit();
      }
    });
  }

  deleteKeyword(_id) {
    this.ajax.CommonAjax("post", "/DocumentTools/named_delete", { id: _id }).subscribe(res => {
      if (res.status === 200) {
        this.notification.create("success", "关键字删除成功", "");
        this.searchSubmit();
      }
    });
  }
}
