import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AjaxService } from "../../../service/ajax/ajax.service";
import { FnService } from "../../../service/fn/fn.service";

@Component({
  selector: "temp-preview",
  templateUrl: "./temp-preview.component.html",
  styleUrls: ["./temp-preview.component.scss"]
})
export class TempPreviewComponent implements OnInit {
  constructor() {}

  _temp_data;
  @Input()
  get temp_data() {
    return this._temp_data;
  }
  set temp_data(val) {
    this._temp_data = val;
  }


  ngOnInit() {

  }

  createTemp() {
    window.location.href = `/tempEdit?fatherTitle=${this.temp_data.template_title}&fatherId=${this.temp_data.id}`;
  }

  createPaper() {
    window.location.href = `/paperEdit?tempId=${this.temp_data.id}`;
  }
}
