import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
  selector: "[input-debounce]"
})
export class InputDebounceDirective implements OnInit, OnDestroy {
  constructor() {}
  subscription: Subscription;
  inputSubject = new Subject();

  @Input()
  debounceTime: number = 500;
  @Output()
  debounceInput = new EventEmitter();
  @HostListener("input", ["$event"])
  inputEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.inputSubject.next(event);
  }

  ngOnInit() {
    this.subscription = this.inputSubject.pipe(debounceTime(this.debounceTime)).subscribe(e => this.debounceInput.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
