import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgZorroAntdModule, NZ_I18N, NzModalRef, zh_CN } from "ng-zorro-antd";
import { registerLocaleData } from "@angular/common";
import zh from "@angular/common/locales/zh";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PlatformComponent } from "./platform/platform.component";
import { LoginComponent } from "./login/login.component";
import { PeperListComponent } from "./platform/pages/peper-list/peper-list.component";
import { TempPersonalListComponent } from "./platform/pages/temp-personal-list/temp-personal-list.component";
import { TempPublicListComponent } from "./platform/pages/temp-public-list/temp-public-list.component";
import { TempEditComponent } from "./platform/pages/temp-edit/temp-edit.component";
import { NgxNeditorModule } from "@notadd/ngx-neditor";
import { ClickDebounceDirective } from "./directive/click-debounce/click-debounce.directive";
import { ClickThrottleDirective } from "./directive/click-throttle/click-throttle.directive";
import { FormValidCheckDirective } from "./directive/form-valid-check/form-valid-check.directive";
import { ClickStopDirective } from "./directive/click-stop/click-stop.directive";
import { SanitizeHtmlPipe } from "./pipe/sanitize-html.pipe";
import { PaperEditComponent } from "./platform/pages/paper-edit/paper-edit.component";
import { PaperEditBasicInfoComponent } from "./platform/pages/paper-edit/paper-edit-basic-info/paper-edit-basic-info.component";
import { PaperEditTempSelectComponent } from "./platform/pages/paper-edit/paper-edit-temp-select/paper-edit-temp-select.component";
import { PaperEditDetailComponent } from "./platform/pages/paper-edit/paper-edit-detail/paper-edit-detail.component";
import { HttpAuth } from "./service/http-auth";
import { InputDebounceDirective } from "./directive/inputDebounce/input-debounce.directive";
import { PaperEditSuccessComponent } from "./platform/pages/paper-edit/paper-edit-success/paper-edit-success.component";
import { PaperPreviewComponent } from "./platform/components/paper-preview/paper-preview.component";
import { TempPreviewComponent } from "./platform/components/temp-preview/temp-preview.component";
import { PaperModifyComponent } from "./platform/pages/paper-modify/paper-modify.component";
import { PrintPageComponent } from "./print-page/print-page.component";
import { SendEmailComponent } from "./platform/components/send-email/send-email.component";
import { XlsxDataSelectComponent } from "./platform/components/xlsx-data-select/xlsx-data-select.component";
import { KeywordListComponent } from "./platform/pages/keyword-list/keyword-list.component";
import { KeywordEditComponent } from "./platform/pages/keyword-edit/keyword-edit.component";
import { EditorModule } from '@tinymce/tinymce-angular';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    PlatformComponent,
    LoginComponent,
    PeperListComponent,
    TempPersonalListComponent,
    TempPublicListComponent,
    TempEditComponent,
    ClickDebounceDirective,
    ClickThrottleDirective,
    FormValidCheckDirective,
    ClickStopDirective,
    SanitizeHtmlPipe,
    PaperEditComponent,
    PaperEditBasicInfoComponent,
    PaperEditTempSelectComponent,
    PaperEditDetailComponent,
    InputDebounceDirective,
    PaperEditSuccessComponent,
    PaperPreviewComponent,
    TempPreviewComponent,
    PaperModifyComponent,
    PrintPageComponent,
    SendEmailComponent,
    XlsxDataSelectComponent,
    KeywordListComponent,
    KeywordEditComponent
  ],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, FormsModule, HttpClientModule, NgZorroAntdModule, ReactiveFormsModule, NgxNeditorModule, PerfectScrollbarModule, EditorModule],
  providers: [{ provide: NZ_I18N, useValue: zh_CN }, { provide: HTTP_INTERCEPTORS, useClass: HttpAuth, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [PaperPreviewComponent, TempPreviewComponent, SendEmailComponent, XlsxDataSelectComponent]
})
export class AppModule { }
