import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
  selector: "[click-debounce]"
})
export class ClickDebounceDirective implements OnInit, OnDestroy {
  constructor() {}
  subscription: Subscription;
  clickSubject = new Subject();

  @Input()
  debounceTime: number = 500;
  @Output()
  debounceClick = new EventEmitter();
  @HostListener("click", ["$event"])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clickSubject.next(event);
  }

  ngOnInit() {
    this.subscription = this.clickSubject.pipe(debounceTime(this.debounceTime)).subscribe(e => this.debounceClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
